<template>
  <div>
    <div class="d-flex">
      <div class="w-100">
        <div class="d-flex justify-content-center">
          <!-- 1 -->
          <div class="w-75">
            <b-input-group class="mb-1">
              <b-input-group-append
                v-b-tooltip.v-secondary.auto.noninteractive="
                  `
                  ${
                    !checkbox_1
                      ? 'Seleccionar todos (incluyendo los que se creen posteriormente)'
                      : 'Deseleccionar todos'
                  }
                `
                "
              >
                <b-form-checkbox
                  class="mt-1"
                  v-model="checkbox_1"
                  @change="
                    () => {
                      if (checkbox_1)
                        can_do_special_behavior = Items.reduce(
                          (acc, cur) =>
                            acc.concat(cur.options.map((x) => x.id)),
                          []
                        );
                      else this.can_do_special_behavior = [];
                    }
                  "
                  name="checkbox-1"
                >
                </b-form-checkbox>
              </b-input-group-append>
              <b-form-input
                v-model="input_search1"
                type="search"
                size="sm"
                :disabled="checkbox_1"
                :placeholder="`Buscar ${Name}...`"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!input_search1"
                  @click="input_search1 = ''"
                  size="sm"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              :disabled="checkbox_1"
              v-model="can_do_special_behavior"
              :select-size="lengthSearchItem1 < 20 ? lengthSearchItem1 : 20"
              size="sm"
              multiple
            >
              <b-form-select-option-group
                v-for="career in searchItems1"
                :key="career.id"
                :label="career.name"
              >
                <b-select-option
                  class="select-option"
                  v-for="matter in career.options.filter(
                    (x) =>
                      $filtered(career.name, input_search1) ||
                      can_do_special_behavior.includes(x.id) ||
                      $filtered(getMatterLabel(x.matter), input_search1)
                  )"
                  :value="matter.id"
                  :key="matter.id"
                  >{{ getMatterLabel(matter.matter) }}
                </b-select-option>
              </b-form-select-option-group>
            </b-form-select>
          </div>
          <!-- acction -->
          <div class="ml-2 d-flex align-center">
            <b-button
              size="sm"
              variant="none"
              v-b-tooltip.v-secondary.auto.noninteractive="`Guardar Permisos`"
              @click="saveAccessTable"
            >
              <b-icon-box-arrow-in-down
                class="h2 mb-0"
                font-scale="1.7"
              ></b-icon-box-arrow-in-down>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";
export default {
  name: "EPMSpecialAccessCard",
  components: {},
  props: {
    Table: {
      type: Object,
      required: true,
    },
    Access: {
      type: Object,
      required: true,
    },
    Items: {
      type: Array,
    },
    TableAccess: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          table: null,
          access: null,
          can_create: false,
          can_read: "",
          can_update: "",
          can_delete: "",
          can_do_special_behavior: "",
        };
      },
    },
    Name: {
      type: String,
      default: "",
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      table_access: { ...this.TableAccess },
      can_do_special_behavior: [],
      checkbox_1: false,
      input_search1: "",
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
    }),
    lengthSearchItem1: function () {
      let item_length = 0;
      this.Items.forEach((element) => {
        item_length += element.options.length + 1;
      });
      return item_length;
    },
    searchItems1: function () {
      return this.Items.filter((item) => {
        return (
          this.$filtered(item.name, this.input_search1) ||
          item.options.some((matter) =>
            this.$filtered(
              this.getMatterLabel(matter.matter),
              this.input_search1
            )
          ) ||
          this.can_do_special_behavior.some((id) =>
            item.options.map((a) => a.id).includes(id)
          )
        );
      }).sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
  },
  methods: {
    getMatterLabel(matter_id) {
      const label = this.matters.find((x) => x.id == matter_id);
      return label ? label.code + " - " + label.name : "";
    },
    saveAccessTable() {
      this.table_access.table = this.Table.id;
      this.table_access.access = this.Access.id;
      if (this.checkbox_1) this.table_access.can_do_special_behavior = "all";
      else {
        this.can_do_special_behavior =
          this.can_do_special_behavior.filter(Number);
        this.table_access.can_do_special_behavior =
          this.can_do_special_behavior.toString();
      }
      if (!isNaN(this.Table.id) && !isNaN(this.Access.id)) {
        if (isNaN(this.table_access.id)) this.createTableAccess();
        else this.updateTableAccess();
      }
    },
    createTableAccess() {
      this.$restful
        .Post(`/teaching/table-access/`, this.table_access)
        .then((response) => {
          this.table_access = response;
          toast("Permiso actualizado");
        });
    },
    updateTableAccess() {
      this.$restful
        .Patch(
          `/teaching/table-access/${this.table_access.id}/`,
          this.table_access
        )
        .then((response) => {
          this.table_access = response;
          toast("Permiso actualizado");
        });
    },
  },
  watch: {},
  mounted() {
    if (!isNaN(this.Table.id) && !isNaN(this.Access.id))
      this.$restful
        .Get(
          `/teaching/table-access/?table=${this.Table.id}&access=${this.Access.id}`
        )
        .then((response) => {
          if (response.length > 0) {
            this.table_access = response[0];
            if (response[0].can_do_special_behavior == "all") {
              this.checkbox_1 = true;
              this.can_do_special_behavior = this.Items.map((x) => x.id);
            } else
              this.can_do_special_behavior = response[0].can_do_special_behavior
                .split(",")
                .map(Number);
          }
        });
  },
  created() {},
};
</script>

<style scoped>
.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-form {
  display: block !important;
}
.select-option {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option {
  text-indent: -16px;
}
</style>